import { find, lowerCase, max, mean, min, uniq } from 'lodash'

import { toWords } from 'number-to-words'

import { simplePluralize as pluralize, pluralizeBeVerb } from 'shared/utils/textGeneration'
import currency from 'shared/utils/formatters/currencyFormatter'
import { getBuildingRentCompsMinMaxAvg } from 'shared/utils/report/incomeApproach'
import { calculateRentPSFByTimePeriod } from 'shared/calculations/rentRoll'

import { RentTypes } from 'shared/constants/buildingComparables'
import { formatList } from 'shared/utils/textHelpers'
import { VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'
import { TIME_PERIODS } from 'shared/constants/report/incomeApproach'
import { MarketConclusionUnits } from 'shared/constants/report/incomeApproach/residential'
import { divide } from 'shared/utils/numberOperations'

const MarketBreakdownSubjectSentence = {
  'all market oriented': "The subject's market rate unit is considered market oriented",
  'all below market':
    'The subject units are all leased at below market rates. We account for this variance qualitatively in the capitalization rate conclusion',
  'mix of market and below market':
    'The subject includes a mix of market oriented and below market rents. We account for this variance qualitatively in the capitalization rate conclusion',
  'mix of market and above market':
    'The subject includes a mix of market oriented and above market rents. We account for this variance qualitatively in the capitalization rate conclusion',
  'mix of below market, market and above market':
    'The subject includes a mix of below market, market oriented and above market rents. We account for this variance qualitatively in the capitalization rate conclusion',
  'all above market':
    'The subject units are all leased at above market rates. We account for this variance qualitatively in the capitalization rate conclusion',
}

type FormValues = {
  unitRentPSFTimePeriod: string
  includePSFAnalysis: boolean
  grossLeasableArea: number
  roomSizeDelta: number
}

type RentReconciliationGroup = {
  units: any[]
  unitGroupName: string
  unitGroupKey: string
  marketConclusionUnit: any
  marketConclusion: number
  marketBreakdown: string
  groupingParameters: any
  estimatedAverageSF?: number
}

type UnitCompGroup = {
  unitGroupName: string
  unitGroupKey: string
  units: any[]
}

export type Props = {
  useBuildingComps: boolean
  rentReconciliationGroup: RentReconciliationGroup
  buildingComps: any[]
  unitCompGroups: UnitCompGroup[]
  valueConclusionType: string
  unitStats: any
  formValues: FormValues
}

type NarrativeValues = {
  unitsLabel: string
  bedroomsLabel: string
  rentTypeList: string
  basisWord: string
  min: string
  max: string
  avg: string
  psfMin: string
  psfMax: string
  psfAvg: string
  compMin: string
  compMax: string
  compAvg: string
  psfCompMin: string
  psfCompMax: string
  psfCompAvg: string
  moreThanOneMarketRateUnit: string
  unitStatsMinGreaterThanZero: boolean
  minEqualsMax: boolean
  asIs: boolean
  hasMarketRateUnits: boolean
  pluralizedRents: string
  marketConclusion: string
  psfMarketConclusion: string
  annualTimePeriod: boolean
  hasSqft: boolean
  marketBreakdownSentence: string
  showMonthlyAndPSF: boolean
}

const getUnitType = (unitGroupName: string) => {
  const bedroomCount = parseInt(unitGroupName, 10)
  return bedroomCount ? `${toWords(bedroomCount)}-bedroom` : 'studio'
}

const filterOutVacantUnitsWithZeroRent = (unit: any) => !(unit.isVacant && !unit.rent)

const getPSFUnitStats = (rentReconciliationGroup: RentReconciliationGroup, unitRentPSFTimePeriod: any) => {
  const { estimatedAverageSF = 0 } = rentReconciliationGroup
  const hasMarketRateUnits = rentReconciliationGroup.units.some(unit => unit.rentType === RentTypes.MARKET_RATE)

  const units = hasMarketRateUnits
    ? rentReconciliationGroup.units.filter(unit => unit.rentType === RentTypes.MARKET_RATE)
    : rentReconciliationGroup.units

  const nonVacantUnits = units.filter(filterOutVacantUnitsWithZeroRent)

  const unitRents = nonVacantUnits.map(unit =>
    calculateRentPSFByTimePeriod(unit.rent, unit.sqft, unitRentPSFTimePeriod)
  )
  const unitSquareFootageAverage = mean(nonVacantUnits.map(unit => unit.sqft)) || estimatedAverageSF

  return {
    min: min(unitRents),
    max: max(unitRents),
    avg: unitRents.length ? mean(unitRents) : null,
    sqft: unitSquareFootageAverage,
  }
}

export const mapDTO = (props: Props): NarrativeValues => {
  const {
    rentReconciliationGroup,
    useBuildingComps,
    buildingComps,
    unitCompGroups,
    valueConclusionType,
    unitStats,
    formValues,
  } = props
  const {
    units,
    unitGroupName,
    unitGroupKey,
    marketConclusionUnit,
    marketConclusion = 0,
    marketBreakdown,
  } = rentReconciliationGroup
  const hasSqft = marketConclusionUnit === MarketConclusionUnits.SF
  const basisWord = hasSqft ? 'square foot' : 'month'
  const decimalScale = hasSqft ? 2 : 0
  const marketRateUnits = units.filter(unit => unit.rentType === RentTypes.MARKET_RATE).map(({ rent }) => rent)
  const hasMarketRateUnits = marketRateUnits.length > 0
  const bedroomsLabel = getUnitType(unitGroupName)
  const { unitRentPSFTimePeriod, includePSFAnalysis } = formValues
  const unitStatsMin = currency.format(unitStats.min, decimalScale)
  const unitStatsMax = currency.format(unitStats.max, decimalScale)
  const avg = currency.format(unitStats.avg, decimalScale)
  const psfUnitStats = getPSFUnitStats(rentReconciliationGroup, unitRentPSFTimePeriod)
  const psfMin = currency.format(psfUnitStats.min, 2)
  const psfMax = currency.format(psfUnitStats.max, 2)
  const psfAvg = currency.format(psfUnitStats.avg, 2)
  const unitsLabel = `${bedroomsLabel} ${pluralize('unit', marketRateUnits.length)}`
  const moreThanOneMarketRateUnit = pluralizeBeVerb(marketRateUnits.length)
  const rentTypes = uniq(units.map(unit => unit.rentType))
  const rentTypeList = formatList(rentTypes.map(lowerCase), { or: true })
  // RentCompRangeSentence
  let compMin = '$0'
  let compMax = '$0'
  let compAvg = '$0'
  let psfCompMin
  let psfCompMax
  let psfCompAvg
  if (useBuildingComps) {
    const buildingRentCompsMinMaxAvg = getBuildingRentCompsMinMaxAvg(buildingComps, unitRentPSFTimePeriod)
    const comp: any =
      buildingRentCompsMinMaxAvg.find((buildingComp: any) => buildingComp.groupingKey === unitGroupKey) || {}

    compMin = hasSqft ? currency.format(comp.minRentSF, decimalScale) : currency.format(comp.minRentMonth)
    compMax = hasSqft ? currency.format(comp.maxRentSF, decimalScale) : currency.format(comp.maxRentMonth)
    compAvg = hasSqft ? currency.format(comp.avgRentSF, decimalScale) : currency.format(comp.avgRentMonth)
    psfCompMin = currency.format(comp.minRentSF, 2)
    psfCompMax = currency.format(comp.maxRentSF, 2)
    psfCompAvg = currency.format(comp.avgRentSF, 2)
  } else {
    const unitCompGroup = find(unitCompGroups, group => group.unitGroupKey === unitGroupKey)
    let psfUnitCompGroups: number[] = []
    let comparableRents: number[] = []

    if (unitCompGroup) {
      psfUnitCompGroups = unitCompGroup.units
        .filter(({ sqft, rent }: any) => rent && sqft)
        .map(({ sqft, rent }: any) => calculateRentPSFByTimePeriod(rent, sqft, unitRentPSFTimePeriod))
      comparableRents = hasSqft ? psfUnitCompGroups : unitCompGroup.units.map(({ rent }: { rent: number }) => rent)
    }

    const rentSum = comparableRents.reduce((sum: number, rent: number) => sum + rent, 0)
    compMin = comparableRents.length ? currency.format(Math.min(...comparableRents), decimalScale) : null
    compMax = comparableRents.length ? currency.format(Math.max(...comparableRents), decimalScale) : null
    compAvg = comparableRents.length ? currency.format(rentSum / comparableRents.length, decimalScale) : null
    const psfRentSum = psfUnitCompGroups.reduce((sum: number, rent: number) => sum + rent, 0)

    psfCompMin = psfUnitCompGroups.length ? currency.format(Math.min(...psfUnitCompGroups), 2) : null
    psfCompMax = psfUnitCompGroups.length ? currency.format(Math.max(...psfUnitCompGroups), 2) : null
    psfCompAvg = psfUnitCompGroups.length ? currency.format(psfRentSum / psfUnitCompGroups.length, 2) : null
  }

  const unitStatsMinGreaterThanZero = unitStats.min > 0
  const asIs = valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS
  const minEqualsMax = unitStatsMin === unitStatsMax
  const pluralizedRents = marketRateUnits.length > 1 ? 'rents' : 'rent'
  // @ts-ignore
  const marketBreakdownSentence: string = MarketBreakdownSubjectSentence[marketBreakdown]
  const formattedMarketConclusion = currency.format(marketConclusion, decimalScale)
  const totalPSFMarketConclusion =
    unitRentPSFTimePeriod === TIME_PERIODS.ANNUALLY ? marketConclusion * 12 : marketConclusion
  const psfMarketConclusion = divide(totalPSFMarketConclusion, psfUnitStats.sqft)
  const formattedPSFMarketConclusion = currency.format(psfMarketConclusion, 2)
  const annualTimePeriod = unitRentPSFTimePeriod === TIME_PERIODS.ANNUALLY
  const showMonthlyAndPSF = !hasSqft && includePSFAnalysis
  return {
    unitsLabel,
    bedroomsLabel,
    rentTypeList,
    basisWord,
    min: unitStatsMin,
    max: unitStatsMax,
    avg,
    psfMin,
    psfMax,
    psfAvg,
    compMin,
    compMax,
    compAvg,
    psfCompMin,
    psfCompMax,
    psfCompAvg,
    moreThanOneMarketRateUnit,
    unitStatsMinGreaterThanZero,
    minEqualsMax,
    asIs,
    hasMarketRateUnits,
    pluralizedRents,
    marketConclusion: formattedMarketConclusion,
    psfMarketConclusion: formattedPSFMarketConclusion,
    annualTimePeriod,
    hasSqft,
    marketBreakdownSentence,
    showMonthlyAndPSF,
  }
}
