import { get } from 'lodash'

import {
  ADDITIONAL_EXPENSES_KEYS,
  DEFAULT_EXPENSES_KEYS,
  NOT_REPORTED_STRING,
  UTILITIES_EXPENSE,
} from 'shared/constants/expenses'

import { EXPENSE_HISTORY_TYPE_TITLES } from 'shared/constants/expenses/expenseHistory'

import { getResponsibilitySentence } from '../../../../../../shared/utils/textGeneration/incomeApproach/expenseForecast'

import { GeneratedText, gtLocked } from '../../../../../../shared/utils/narrative/GeneratedText'
import { BasisTypes } from '../../../../../../shared/constants/report/incomeApproach/expenses'

import { toPercents } from '../../../../shared/utils/numberOperations'
import ExpensesCalculations from '../../../../../../shared/report-calculations/income-approach/expenses/expenses-calculations'

const getExpenseChipValues = ({ value, basis, forecastedAnnualExpense }) => {
  return {
    forecastedExpense: { value, basis },
    forecastedAnnualExpense,
  }
}

const getReservesChipValues = ({ values, value, basis, forecastedAnnualExpense, expenseHistory }) => {
  const projection = expenseHistory.expenseHistory.find(
    ({ expensePeriod }) => expensePeriod === EXPENSE_HISTORY_TYPE_TITLES.PROJECTION
  )
  const projectedExpenses = get(projection, 'expenses') || []
  const projectedReserves = projectedExpenses.find(expense => expense.id === DEFAULT_EXPENSES_KEYS.reserves) || {}
  const reservesIsProjected = projectedReserves.reported
  const projectedReservesValue = projectedReserves[basis]

  const ownerDidProjectExpense = values.expenseDataSource === 'Owner' && reservesIsProjected

  return {
    ownerDidProjectExpense,
    projectedReserves: { value: projectedReservesValue, basis },
    forecastedExpense: { value, basis },
    forecastedAnnualExpense,
  }
}

const getManagementChipValues = ({ category, values, value, basis, forecastedAnnualExpense }) => {
  const percentOfEgi = values[category]?.percentOfEgi
  const formattedPercentOfEgi = percentOfEgi ? `${toPercents(percentOfEgi)}%` : `${NOT_REPORTED_STRING}%`
  const numberOfResidentialUnits = values.numberOfResidentialUnits
  const numberOfCommercialUnits = values.numberOfCommercialUnits

  return {
    forecastedExpense: { value, basis },
    percentOfEgi: formattedPercentOfEgi,
    forecastedAnnualExpense,
    numberOfResidentialUnits,
    numberOfCommercialUnits,
  }
}

const getElectricityFuelChipValues = ({ values, expenseHistory }) => {
  const { fuel, electricity, squareFootage, numberOfResidentialUnits, totalRoomCount } = values
  const fuelAnnualTotal = ExpensesCalculations.calculateTotalFromPerBasis(
    fuel?.concludedValue || 0,
    fuel?.basis || BasisTypes.PER_SF,
    { squareFootage, numberOfUnits: numberOfResidentialUnits, numberOfRooms: totalRoomCount }
  )
  const electricityAnnualTotal = ExpensesCalculations.calculateTotalFromPerBasis(
    electricity?.concludedValue || 0,
    electricity?.basis || BasisTypes.PER_SF,
    { squareFootage, numberOfUnits: numberOfResidentialUnits, numberOfRooms: totalRoomCount }
  )

  return {
    forecastedAnnualExpense: fuelAnnualTotal + electricityAnnualTotal,
    expenseHistory,
  }
}

export const getForecastDiscussionChipValues = (category, values, expenseHistory) => {
  const basis = get(values, `${category}.basis`, BasisTypes.PER_SF)
  const value = get(values, `${category}.concludedValue`, 0)
  const { squareFootage, numberOfUnits, totalRoomCount } = values

  const forecastedAnnualExpense = ExpensesCalculations.calculateTotalFromPerBasis(value, basis, {
    squareFootage,
    numberOfUnits,
    numberOfRooms: totalRoomCount,
  })

  switch (category) {
    case DEFAULT_EXPENSES_KEYS.insurance:
    case UTILITIES_EXPENSE.key:
    case DEFAULT_EXPENSES_KEYS.electricity:
    case DEFAULT_EXPENSES_KEYS.fuel:
    case DEFAULT_EXPENSES_KEYS.waterAndSewer:
    case DEFAULT_EXPENSES_KEYS.repairsAndMaintenance:
    case DEFAULT_EXPENSES_KEYS.payrollAndBenefits:
    case DEFAULT_EXPENSES_KEYS.generalAndAdministrative:
    case DEFAULT_EXPENSES_KEYS.legalAndProfessionalFees:
    case DEFAULT_EXPENSES_KEYS.miscellaneous:
      return getExpenseChipValues({ value, basis, forecastedAnnualExpense })

    case DEFAULT_EXPENSES_KEYS.reserves:
      return getReservesChipValues({ values, value, basis, forecastedAnnualExpense, expenseHistory })

    case DEFAULT_EXPENSES_KEYS.management:
      return getManagementChipValues({ category, values, value, basis, forecastedAnnualExpense })

    case ADDITIONAL_EXPENSES_KEYS.electricityFuel:
      return getElectricityFuelChipValues({ values, expenseHistory })

    default:
      return getExpenseChipValues({ value, basis, forecastedAnnualExpense })
  }
}

const appendForecastSentence = gt => {
  const perBasisText = gtLocked('forecastedExpense.basis', 'formatBasisToString')
  const forecastedExpense = gtLocked('forecastedExpense', 'formatCurrencyByBasis')
  const forecastedAnnualExpense = gtLocked('forecastedAnnualExpense', 'formatCurrencyInt')

  gt.t`Based on the information above, we have projected this expense at ${forecastedExpense} ${perBasisText}, `
  gt.t`or ${forecastedAnnualExpense} annually.`
}

// Electricity, Water & Sewer, Legal & Professional Fees, Miscellaneous Forecast Writeups
export const generateBasicForecastWriteup = () => {
  const gt = new GeneratedText()

  appendForecastSentence(gt)

  return gt.getParts()
}

export const generateInsuranceWriteup = () => {
  const gt = new GeneratedText()
  const perBasisText = gtLocked('forecastedExpense.basis', 'formatBasisToString')

  gt.t`Insurance costs vary by the type of coverage. Costs are generally lower (on a ${perBasisText} basis) `
  gt.t`for larger buildings and for multi-building policies. `
  appendForecastSentence(gt)

  return gt.getParts()
}

export const generateFuelWriteup = () => {
  const gt = new GeneratedText()
  const perBasisText = gtLocked('forecastedExpense.basis', 'formatBasisToString')
  const forecastedExpense = gtLocked('forecastedExpense', 'formatCurrencyByBasis')
  const forecastedAnnualExpense = gtLocked('forecastedAnnualExpense', 'formatCurrencyInt')

  gt.t`Based on the information above, our forecast of heating fuel expense is ${forecastedExpense} ${perBasisText}, `
  gt.t`or ${forecastedAnnualExpense} annually, which reflects investor expectations.`

  return gt.getParts()
}

export const generateRepairsAndMaintenanceWriteup = () => {
  const gt = new GeneratedText()

  /* eslint-disable max-len */
  gt.t`This expense varies depending on building age, management philosophy, services provided, and accounting methodology. `
  gt.t`Some management companies expense items that are normally included as capital costs. `
  gt.t`In addition, repair and maintenance costs may change from year to year; in some cases, repairs that require attention may be postponed due to cash flow considerations. `
  /* eslint-enable max-len */
  appendForecastSentence(gt)

  return gt.getParts()
}

export const generatePayrollAndBenefitsWriteup = () => {
  const gt = new GeneratedText()

  // eslint-disable-next-line max-len
  gt.t`Payroll costs will cover building staff whose duties will include trash removal, common area cleaning and general maintenance. `
  gt.t`Payroll taxes and fringes cover state and federal taxes as well as benefits that building employees receive. `
  appendForecastSentence(gt)

  return gt.getParts()
}

export const generateGeneralAndAdministrativeWriteup = () => {
  const gt = new GeneratedText()

  // eslint-disable-next-line max-len
  gt.t`This expense allows for any expenditure not included in the above categories including general administrative costs, `
  gt.t`accounting/legal, permits and dues, miscellaneous charges, office expense, etc. `
  appendForecastSentence(gt)

  return gt.getParts()
}

export const generateManagementWriteup = () => {
  const percentOfEgi = gtLocked('percentOfEgi')
  const perBasisText = gtLocked('forecastedExpense.basis', 'formatBasisToString')
  const forecastedExpense = gtLocked('forecastedExpense', 'formatCurrencyByBasis')
  const forecastedAnnualExpense = gtLocked('forecastedAnnualExpense', 'formatCurrencyInt')

  const gt = new GeneratedText()

  gt.t`Typically, management fees for similar properties range from 2% to 6% of effective gross income. `
  gt.t`We have projected this expense at ${percentOfEgi} of effective gross income, `
  gt.t`which equates to ${forecastedExpense} ${perBasisText} or ${forecastedAnnualExpense} annually.`

  return gt.getParts()
}

export const generateReservesWriteup = ({ ownerDidProjectExpense }) => {
  const perBasisText = gtLocked('forecastedExpense.basis', 'formatBasisToString')
  const forecastedExpense = gtLocked('forecastedExpense', 'formatCurrencyByBasis')
  const forecastedAnnualExpense = gtLocked('forecastedAnnualExpense', 'formatCurrencyInt')
  const projectedReserves = gtLocked('projectedReserves', 'formatCurrencyByBasis')

  const gt = new GeneratedText()

  gt.t`This expense provides for the periodic replacement of building components that wear out more rapidly `
  gt.t`than the building itself and that must be replaced periodically during the building's economic life. `

  if (ownerDidProjectExpense) {
    gt.t`The owner projected this expense at ${projectedReserves} ${perBasisText}. `
  } else {
    gt.t`We note the owner did not indicate this expense. `
  }

  gt.t`We have projected this expense at ${forecastedExpense} ${perBasisText}, or ${forecastedAnnualExpense} annually.`

  return gt.getParts()
}

export const generateCustomExpenseForecastWriteup = () => {
  const gt = new GeneratedText()

  const perBasisText = gtLocked('forecastedExpense.basis', 'formatBasisToString')
  const forecastedExpense = gtLocked('forecastedExpense', 'formatCurrencyByBasis')
  const forecastedAnnualExpense = gtLocked('forecastedAnnualExpense', 'formatCurrencyInt')

  gt.t`We have projected this expense at ${forecastedExpense} ${perBasisText}, `
  gt.t`or ${forecastedAnnualExpense} annually.`

  return gt.getParts()
}

export const generateElectricityFuelWriteup = ({ expenseHistory }) => {
  const gt = new GeneratedText()
  const forecastedAnnualExpense = gtLocked('forecastedAnnualExpense', 'formatCurrencyInt')

  const responsibilitySentence = getResponsibilitySentence(expenseHistory)
  gt.t`${responsibilitySentence}`
  gt.t`Based on the information above, we have projected this expense at ${forecastedAnnualExpense} annually.`

  return gt.getParts()
}
