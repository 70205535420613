import React from 'react'
import { isNil, get } from 'lodash'
import { ReactSVG } from 'react-svg'
import PropTypes from 'prop-types'

import { AppBar, Grid, Button, Typography } from '@material-ui/core'

import { PropertyTypes } from 'shared/constants'

import StarIcon from '../../../../../images/yellow_star.png'
import StickyFormTopPanel from '../../../../../layout/StickyFormTopPanel'
import CommercialIcon from '../../../../../../shared/images/income_icon.svg'
import wrapForm from '../../../../wrapForm'
import { getAdjustedRentPSF } from '../../../../../../shared/utils/calculations'
import { Labels, DATA_PATH } from '../constants'

import CommercialCompGroupDiscussionPanel from './CommercialCompGroupDiscussionPanel'
import CommercialCompGroupsStatisticsPanel from './CommercialCompGroupsStatisticsPanel'

const styles = theme => ({
  stickyPanel: {
    marginBottom: 16,
  },
  statisticsBar: {
    padding: '10px 0',
    boxShadow: 'none',
  },
  firstPanel: {
    position: 'relative',
    '&:after': {
      content: "''",
      display: 'block',
      width: 2,
      height: '70%',
      backgroundColor: '#E5E5E5',
      position: 'absolute',
      top: '20%',
      right: 0,
    },
  },

  totalGroupsIcon: {
    width: 20,
    height: 20,
  },
  selectedGroupIcon: {
    width: 25,
    height: 25,
    fill: theme.palette.text.primary,
  },

  buttonContainer: {
    padding: '0 0 16px 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

class CommercialCompGroupsDiscussion extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    compGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        groupName: PropTypes.string.isRequired,
        units: PropTypes.arrayOf(PropTypes.object.isRequired),
        discussion: PropTypes.object.isRequired,
        marketRentConclusion: PropTypes.number.isRequired,
      })
    ).isRequired,
    statistics: PropTypes.shape({
      subject: PropTypes.object,
      comps: PropTypes.object,
    }).isRequired,
    isCommercialOnly: PropTypes.bool.isRequired,
  }

  state = {
    discussionTableExpansionStates: [],
  }

  componentDidMount() {
    const newCompGroupsLength = this.props.compGroups.length
    this.setState({ discussionTableExpansionStates: Array(newCompGroupsLength).fill(true) })
  }

  onChangeItemExpansionState = ({ index }) => {
    const newStatuses = this.state.discussionTableExpansionStates.slice()
    if (isNil(index)) {
      const newValue = !newStatuses.some(Boolean)
      newStatuses.fill(newValue)
    } else {
      newStatuses[index] = !newStatuses[index]
    }
    this.setState({ discussionTableExpansionStates: newStatuses })
  }

  render() {
    const { classes, compGroups, statistics, isCommercialOnly, form } = this.props
    const { discussionTableExpansionStates } = this.state
    const hasExpandedPanel = discussionTableExpansionStates.some(Boolean)
    const { rentRollRentBasis } = form.values

    const collapseButtonLabel = hasExpandedPanel ? 'Collapse all' : 'Expand all'
    const hasComps = compGroups.length > 0

    return (
      <div>
        <StickyFormTopPanel className={classes.stickyPanel}>
          <AppBar className={classes.statisticsBar} position="static" color="default">
            <Grid container>
              <Grid item xs={6} lg={4} className={classes.firstPanel}>
                <CommercialCompGroupsStatisticsPanel
                  {...statistics.subject}
                  type="subject"
                  icon={<img className={classes.totalGroupsIcon} src={StarIcon} alt="" />}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <CommercialCompGroupsStatisticsPanel
                  {...statistics.comps}
                  type="comps"
                  icon={
                    <ReactSVG
                      beforeInjection={svg => {
                        svg.classList.add(classes.selectedGroupIcon)
                      }}
                      src={CommercialIcon}
                    />
                  }
                />
              </Grid>
            </Grid>
          </AppBar>
        </StickyFormTopPanel>

        {hasComps && (
          <Grid container>
            <Grid item md={12} lg={10}>
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.onChangeItemExpansionState}
                  data-qa="collapse-button"
                >
                  {collapseButtonLabel}
                </Button>
              </div>
            </Grid>
            <Grid className={classes.contentWrapper} item md={12} lg={10}>
              {compGroups.map((group, index) => {
                return (
                  <CommercialCompGroupDiscussionPanel
                    form={form}
                    key={index}
                    index={index}
                    isExpanded={discussionTableExpansionStates[index]}
                    onToggle={this.onChangeItemExpansionState}
                    rentRollRentBasis={rentRollRentBasis}
                    isCommercialOnly={isCommercialOnly}
                    {...group}
                  />
                )
              })}
            </Grid>
          </Grid>
        )}

        {!hasComps && (
          <Grid container item>
            <Typography variant="subtitle1" data-qa="no-groups">
              {Labels.THERE_ARE_NO_COMMERCIAL_GROUPS}
            </Typography>
          </Grid>
        )}
      </div>
    )
  }
}

const mapCompGroups = (compGroups, selectedRentComps, reconciliationGroups) => {
  return compGroups.map(compGroup => ({
    ...compGroup,
    units: compGroup.units.map(unit => {
      const reconciliationGroup = reconciliationGroups.find(group => group._id === compGroup._id)
      return {
        ...unit,
        adjustedRentPerSF: getAdjustedRentPSF(unit, unit.rentPerSf, reconciliationGroup.summary.leaseTermsCalcType),
      }
    }),
  }))
}

const formOptions = {
  styles,
  heading: Labels.COMMERCIAL_COMP_GROUPS_DISCUSSION,
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const formValues = get(state, 'report.reportData.incomeApproach.commercialIncome.commercialCompGroupsDiscussion')
  const reconciliation = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialRentReconciliation.reconciliationGroups'
  )
  const selectedRentComps = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialRentComps.selectedRentComps',
    []
  )
  const compGroups = get(formValues, 'items', [])
  const statistics = get(formValues, 'statistics', {})
  const reconciliationGroups = get(reconciliation, 'items', [])

  const propertyType = get(state, 'report.reportData.propertyType')

  return {
    compGroups: mapCompGroups(compGroups, selectedRentComps, reconciliationGroups),
    statistics,
    isCommercialOnly: propertyType === PropertyTypes.COMMERCIAL,
  }
})(CommercialCompGroupsDiscussion)
