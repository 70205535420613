export const CAP_RATE_DISCUSSION_TOOLTIP =
  "The following generated commentary will appear in the Income Approach's Income Capitalization Conclusion of your report."

export const SiteAreaLabels = {
  sf: 'square feet',
  acre: 'acres',
}

export const tableRowHeaderBase =
  'font-weight:normal;border-bottom:1px solid #d7deea;text-align: center;vertical-align: middle;border-radius: 0px!important;margin: none; '
