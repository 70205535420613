import { get } from 'lodash'

import ResidentialCalculations from 'shared/report-calculations/income-approach/residential/residential-calculations'

type FormValues = {
  valueAsStabilized: boolean
  hasVacantUnits: boolean
  totalAverageRent: number
}

type Report = {
  valueAsStabilized: boolean
  residential: { units: any[] }
}

type NarrativeValues = {
  totalAverageRent: number
  isForecast: boolean
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  const { valueAsStabilized, hasVacantUnits, totalAverageRent } = formValues
  const isForecast = valueAsStabilized || hasVacantUnits

  return {
    totalAverageRent,
    isForecast,
  }
}

export const mapDataModel = (report: Report): NarrativeValues => {
  const valueAsStabilized = get(report, 'valueAsStabilized')
  const residentialUnits = get(report, 'residential.projectedRentRoll', [])
  const totalAverageRent = ResidentialCalculations.calculateTotalAverageRent(residentialUnits)
  const hasVacantUnits = residentialUnits.some((unit: any) => unit.isVacant)

  return {
    totalAverageRent,
    isForecast: valueAsStabilized || hasVacantUnits,
  }
}
